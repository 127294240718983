:root {
  /**
    *
    * Colors */
  --base-white: #ffffff;
  --base-black: #000000;

  --primary-100: #03060b;
  --primary-blue: #f7f7fb;
  --primary-blue-50: #f9f9ff;

  --grey-hr: #e4e4e4;
  --light-grey: #e9e9e9;
  --grey-60: #717d8a;
  --grey-100: #f1f1f1;
  --grey-200: #eaecee;
  --grey-400: #a8b0b9;
  --grey-500: #717d8a;
  --grey-600: #4f5b67;
  --grey-800: #242d35;
  --grey-900: #0c1116;

  --blue-900: #041e42;
  --blue-800: #144589;
  --blue-600: #3333d1;
  --blue-50: #3d64f0;
  --primary-blue-1: #84abf8;
  --link-primary-blue: #0d4b9f;
  --blue-neutral: #d1e9ff;

  --text-primary: #344054;

  --green-100: #e6fbd9;
  --green-500: #44c13c;

  /**
    *
    * Font sizes */
  --large: 16px;
  --normal: 14px;
  --small: 12px;

  /**
    *
    * Sizes */
  --max-table-width: 1350px;
  --information-container-width: 385px;
}
