@import "/src/app/_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Importing Bootstrap SCSS file. */

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled {
  & > i {
    opacity: 0.5;
  }
}

.fs-7 {
  font-size: 0.75rem;
}

/**
*
* Buttons */

.btn-primary {
  padding: 5px 20px !important;
  background-color: var(--blue-50) !important;
  border-color: var(--blue-50) !important;
}

.btn-primary:hover {
  background-color: var(--blue-50) !important;
  opacity: 0.9 !important;
}

.form-label {
  font-size: 14px;
}

ul.nav2.nav2-tabs {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding-left: 0;
  box-sizing: border-box;
  box-shadow: inset 0px -0.2rem 0px 0px $gray-200;

  .nav2-item {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    button {
      background: none;
      border: none;
      color: $gray-500;

      &.active {
        color: black;
        font-weight: 600;
        border-bottom: 0.2rem solid var(--blue-50);
      }
    }
  }
}

// sortable table icons
th[sortable] {
  // position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &:not(.no-sort-icon) {
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      background: url("./assets/svgs/sortable.svg") no-repeat;
      background-position: center;
      transition: transform 0.2s ease-in-out;
      margin-left: 5px;
      background-size: 14px;
      width: 14px;
      height: 14px;
    }
  }

  &.desc::after,
  &.asc::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC)
    //     no-repeat;
    background: url("./assets/svgs/caret.svg") no-repeat;
    background-position: center;
    transition: transform 0.2s ease-in-out;
    // vertical-align: text-bottom;
    margin-left: 5px;
    background-size: 14px;
    width: 14px;
    height: 14px;
  }

  &.asc::after {
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
  }
}

.popover-reports-table {
  width: 95vw;
  --bs-popover-max-width: 95vw !important;
  height: 500px;
  transform: translate(20px) !important;
  overflow: scroll;

  & .popover-arrow {
    display: none !important;
  }
}

.fpc-table {
  border: 1px solid var(--grey-hr);
  border-radius: 0.625rem;
  overflow-x: scroll;

  thead {
    border-bottom: 1px solid var(--grey-hr);
  }

  th {
    font-weight: 400;
    font-size: 12px;
  }

  td {
    font-size: 14px;
  }
}

// font size classes
.fs-small {
  font-size: var(--small) !important;
}

.fs-normal {
  font-size: var(--normal) !important;
}

// ngselect multi-select
.ng-select-multiple {
  .ng-value {
    background: var(--primary-blue-50) !important;
    color: var(--blue-900) !important;

    .ng-value-icon {
      border-color: var(--primary-blue-50) !important;
    }
  }
}

// bootsrap select style
.form-select {
  background-image: url(/assets/svgs/select-caret.svg) !important;
  background-position: right 0.55rem center !important;
  background-size: 14px 12px !important;
  background-repeat: no-repeat !important;
}

// extra classes
.cursor-pointer {
  cursor: pointer;
}

// pagination
.page-item {
  .page-link {
    color: var(--text-primary);
  }

  &.active {
    .page-link {
      border: 1px solid var(--blue-neutral);
      background-color: var(--blue-neutral);
    }
  }
}

//checkbox
.form-check-input:not(.switch) {
  &:checked {
    background-color: var(--blue-50);
    border-color: var(--blue-50);
  }
}
